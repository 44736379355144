<template lang="pug">
	.container-xs-24-wrapper
		h1.container-xs-24.mb-4 {{ $t('menu.coverAndTitle') }}

		.wizard-wrap
			.container-xs-24
				b-card.card-main-data.mb-4
					form-add-project(@success='' @hideSaveButton='($e) => hideSaveButtonProjectData = $e')
					.text-left(v-if='!hideSaveButtonProjectData')
						b-btn.btn-xs-100(variant='primary' @click='saveProject()' :disabled='isPendingProject') {{$t('forms.save')}}
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddProject from '@/components/Form/AddProject';
import { bus } from '@/main.js';

export default {
    name: 'ProjectSettings',
    props: ['id'],
    components: {
        'form-add-project': AddProject,
    },
    metaInfo() {
        return { title: this.getTitle };
    },
    data: () => ({
        hideSaveButtonProjectData: false,
    }),
    computed: {
        ...mapGetters({
            item: 'project/opened',
            isPendingProject: 'project/isPending',
        }),

        getTitle() {
            if (this.item) {
                return this.item.title;
            }

            return 'Проект';
        },
    },

    methods: {
        ...mapActions({
            activate: 'project/activate',
            getItem: 'project/id',
            edit: 'project/edit',
        }),
        async saveProject() {
            let r = { response: true };
            await bus.$emit('validateForm', r);

            if (r.response) {
                new Promise((res, rej) => {
                    bus.$emit('sendForm', { res, rej });
                })
                    .then(() => this.$notify('success', this.$t('success.projectEdit')))
                    .catch(v => this.$notify('error', v));
            }
        },
    },
    created() {},

    mounted() {},

    beforeDestroy() {},

    watch: {},
    destroyed() {},
};
</script>

<style scopled lang="scss"></style>
